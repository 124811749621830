/* eslint-disable react/no-unescaped-entities */
import React, {useEffect} from 'react'
import styles from './confirmation-souscription.css';
import Navbar from '../../../component/navbar.js';
import felicitations from '../../../assets/images/felicitations.png';
import { Link } from "react-router-dom";
import Footer from '../../../component/footer.js';
import { useLocation } from "react-router-dom";

export default function ConfirmationSouscrition() {
    const location = useLocation();
    //const [message, setMessage] = useState(undefined);
    const {  message } = location.state || {}; 
    useEffect(()=>{

    },);
    return (
        <div>
            <Navbar/>
        <div className={styles}>
            
            <section className="py-28-a w-full table relative bg-[url('../../assets/images/bg/bg-6.jpg')]" style={{backgroundSize:'cover'}} id="home">
                <div className="absolute inset-0 navbar-background"></div>

               

                <div className="container">
                    <div className="grid grid-cols-1 pb-8 text-center mt-10">
                        <h6 className="font-medium leading-normal text-3xl mt-10 text-white hidden">Félicitations !</h6>
                    </div>
                </div>
            </section>
            <section className="relative" id="about">
                <div>
                    <h1 className='confirmation-msg hidden'>{message}</h1>
                </div>
                <div className="lg:col-span-7 md:col-span-6 hidden">
                            <img src={felicitations} alt="" />
                        </div>
                <div className="container mx-auto">
                    <div className="items-center">
                        
                        
                    
                        <div >
                        <div className="card-success">
                            <div id='checkmark-div'>
                                <i className="checkmark">✓</i>
                            </div>
                            <div style={{textAlign: 'center', width: "600px"}}> 
                                <h1 className='msg-success' >Succès</h1> 
                                <p className='details-message'>{message}</p>
                            </div>
                            </div>

                        </div>

                    </div>

                   
                </div>
                <div className='partners-div'>
                <div>
                        <p>Vous pouvez effectuer les paiements dans une agence locale</p>
                        <strong>Burkina Faso</strong>
                        <br/>
                            <h1 className='title-agencies'>Nos agences partenaires</h1>

                                <ul className="agency-list">
                                    <li className="agency-item">
                                        <h2>Patience Plus</h2>
                                        <p>Ouagadougou , Burkina Faso</p>
                                    </li>
                                    <li className="agency-item">
                                        <h2>Justi Shop</h2>
                                        <p>Ouagadougou: Burkina Faso</p>
                                    </li>
                                </ul>
                </div>
                </div>

                <div>
                        <div className="action-buttons">
                                <button  className="view-route-btn"><Link to={'/compte/'} > Mon compte</Link></button>
                                <button  className="interested-btn"><Link to={'/'} >Retour à l'accueil</Link></button>
                        </div>
                    </div>
            </section>
        </div>
        <Footer/>
        </div>
    )
}