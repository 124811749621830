/* eslint-disable react/no-unescaped-entities */
import React, { useEffect, useState } from 'react'
import { Link } from "react-router-dom";
import Navbar from '../../../component/navbar.js';
import Footer from '../../../component/footer';
import styles from './pricing.css';
import logistique from '../../../assets/images/logistique.png';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { useNavigate } from "react-router-dom";
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';

export default function Pricing() {
    const backend_url = process.env.REACT_APP_BACKEND_URL;
    const [formules, setFormules] = useState([]);
    const [alignment, setAlignment] = useState('Mensuel');
    const navigate = useNavigate();

    const handleChange = (
        event,
        newAlignment
      ) => {
        setAlignment(newAlignment);
        console.log(newAlignment);
      };
    
    function navigateToCheckout(formule){
        let prix = 0;
        if(alignment === "Mensuel"){
            prix = formule.prix_mensuel;
        }else{
            prix = formule.prix_annuel;
        }
        navigate("/checkout", {state:{formule: formule, prix: prix, forfait: alignment}});
    }


    async function getFormules() {
        try {
          const response = await fetch(backend_url+'/plan_souscription/');
          const data = await response.json();
          setFormules(data);
          console.log(formules);
        } catch (error) {
          console.error(error);
        }
    }

    useEffect(()=>{
        document.title = "Nos abonnements";
        getFormules();
    },[]);



    return (
        <div className={styles}>
            <body className="font-inter text-base text-slate-950 dark:text-white dark:bg-slate-900">

                <Navbar />

                <section className="py-28-a w-full table relative bg-[url('../../assets/images/bg/bg-5.jpg')] bg-bottom bg-no-repeat" id="home">
                    <div className="absolute inset-0 navbar-background"></div>

                    <div className="container">
                        <div className="grid grid-cols-1 pb-8 text-center mt-10">
                            <h3 className="font-medium leading-normal text-3xl mt-10 text-white">Nos formules d'abonnement</h3>
                        </div>
                    </div>
                </section>

                <section className="container">
                    <div>
                    <h1 className='mb-6 md:text-xl text-xl font-medium titre-about'>Découvrez nos formules d'abonnement et trouvez celle qui vous correspond !</h1>

                    <p className='description-about'>Chez Colismi, nous avons conçu des abonnements adaptés à tous les besoins. Que vous soyez un utilisateur occasionnel ou un passionné en quête de services exclusifs, nos formules flexibles vous offrent la liberté de choisir.</p>
                    <br/>
                    <strong className='description-about'>NB: Nos abonnements concernent les villes de Paris et Ouaga</strong>
                    <br/>
                    <Stack direction="row" spacing={1} marginTop={2}>
                            <Chip label="Vous n'avez pas de carte bancaire? pas de panique vous pourrez quand meme payer l'abonnement" color="success" />
                    </Stack>
                    </div>
                    
                </section>
                <section className="container">
                    <ToggleButtonGroup
                        color="primary"
                        value={alignment}
                        exclusive
                        onChange={handleChange}
                        className='plan-type'
                        aria-label="Platform"
                        >
                        <ToggleButton  color='success' value="Mensuel">Mensuel</ToggleButton>
                        <ToggleButton color='primary' value="Annuel">Annuel</ToggleButton>
                    </ToggleButtonGroup>
                </section>
                <section className="relative">
                    <div className="container">
                        <div className="pricing-container">
                            {formules.map((formule, index) =>{


                                return(
                                    <div className="p-6 plan-abonnement" key={index}>
                                    <div className="pb-8">
                                        <h3 className="mb-6 font-medium titre-formule">{formule.nom}</h3>
                                        <div className="mb-6 dark:text-white/50">
                                            <span className="relative h6 -top-5 text-xl"></span>
                                            
                                            {alignment == "Mensuel" &&
                                                <div>
                                                    <span className="text-2xl h6 font-medium prix-formule">{formule.prix_mensuel}</span>
                                                    <span className="inline-block h6 ms-1 prix-formule">€/ mois</span>
                                                </div>
                                            }
                                             {alignment == "Annuel" &&
                                                <div>
                                                    <span className="text-2xl h6 font-medium prix-formule">{formule.prix_annuel}</span>
                                                    <span className="inline-block h6 ms-1 prix-formule">€/ An</span>
                                                </div>
                                            }
                                            
                                        </div>
                                        <p className="mb-6 text-slate-400 description-formule">{formule.description}</p>
                                        <img className='image-plan' src={formule.image}/>
                                        <button className="w-full view-route-btn" onClick={() => navigateToCheckout(formule)}>Je souscris</button>
                                        
                                    </div>
                                    <div className="border-b border-slate-200 dark:border-slate-200/5"></div>
                                        <ul className="self-start pt-8  details-abonnement">
                                            {
                                            Object.entries(formule.features).map(([key, value]) => (

                                                <li className="flex items-center my-1 text-slate-400" key={key}>
                                                    {value == "Oui" &&
                                                    <div className='formule-feature'>
                                                            <i className="uil uil-check-circle text-sm text-green-600 me-2"></i>
                                                            <span>{key}</span>
                                                        </div>
                                                    }
                                                    {value == "Non" &&
                                                    <div className='hidden'>
                                                            <i className="uil uil-check-circle text-sm text-red-700 me-2"></i>
                                                            <span>{key}</span>
                                                        </div>
                                                    }
                                                    
                                                
                                                </li>
                                            ))

                                                
                                            }
                                        </ul>
                                    <div className='conditions-abonnement'>
                                        <br/>
                                        <h1 className='titre-condition'>Conditions</h1>
                                        <ul>
                                        {
                                           Object.entries(formule.conditions).map(([key, value]) => (

                                            <li className="flex items-center my-1 text-slate-400" key={key}>
                                                
                                                   <div className='formule-feature'>
                                                        <span>{value}</span>
                                                    </div>
                                            </li>
                                          ))

                                            
                                        }

                                        </ul>

                                    </div>
                                </div>
                                )

                            }

                            )
                           
                            }

                            
                        </div>
                    </div>

                </section>


                <section className="relative py-3">
                    <div className="container hidden">
                        <div className="grid md:grid-cols-3 grid-cols-1 gap-[30px] items-center">
                                    
                        </div>
                    </div>

                    <div className="container lg:mt-24 mt-16">
                        <div className="grid grid-cols-1 text-center">
                            <h3 className="mb-6 md:text-2xl text-xl font-medium">Vous avez des questions ? Contactez nous</h3>

                            <p className="text-slate-400 max-w-xl mx-auto">Vous ne trouvez pas de formule qui répond à votre besoin, n'hésitez pas à nous contacter.</p>

                            <div className="mt-6">
                                <button className="interested-btn">
                                    <Link to="/contactus" ><i className="uil uil-phone align-middle me-2"></i> Contactez nous</Link>
                                </button>
                            </div>
                        </div>
                    </div>
                </section>
                <Footer />
            </body>
        </div>
    )
}
